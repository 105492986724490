(function ($) {
  Drupal.behaviors.replenishmentOverlay = {
    attach: function (context) {
      jQuery('.js-replenish-overlay', context).on('click', function (e) {
        site.template.get({
          name: 'replenishment_overlay',
          callback: function (html) {
            generic.overlay.launch({
              content: html,
              height: 'auto',
              cssClass: 'qs-overlay-cbox',
              onLoad: function () {
                jQuery.colorbox.resize();
              }
            });
          }
        });
      });
    }
  };
})(jQuery);
